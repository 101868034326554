
@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@400;500&display=swap');

body {
font-family: 'Space Grotesk', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  width:100%;
  padding:0;
  margin:0;
  overflow-x: hidden;
  
}

@media screen and (max-width: 800px) {
  
h1 h2{
  font-size:0.8rem;
}
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
